// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 10 indigo-coolGrey ==========================================
:root {
  // PRIMARY COLOR
  --c-primary-50: 238, 242, 255;
  --c-primary-100: 224, 231, 255;
  --c-primary-200: 199, 210, 254;
  --c-primary-300: 165, 180, 252;
  --c-primary-400: 24, 162, 254;
  --c-primary-500: 18, 126, 191;
  --c-primary-600: 79, 70, 229;
  --c-primary-700: 67, 56, 202;
  --c-primary-800: 55, 48, 163;
  --c-primary-900: 49, 46, 129;

  // SECONDARY COLOR
  --c-secondary-50: 240, 253, 250;
  --c-secondary-100: 204, 251, 241;
  --c-secondary-200: 153, 246, 228;
  --c-secondary-300: 153, 246, 228;
  --c-secondary-400: 45, 212, 191;
  --c-secondary-500: 20, 184, 166;
  --c-secondary-600: 13, 148, 136;
  --c-secondary-700: 15, 118, 110;
  --c-secondary-800: 17, 94, 89;
  --c-secondary-900: 19, 78, 74;

  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 31, 41, 55;
  --c-neutral-900: 17, 24, 39;
}

// THEME PALETTE 1 ==========================================
.theme-cyan-blueGrey {
  --c-primary-50: 236, 254, 255;
  --c-primary-100: 207, 250, 254;
  --c-primary-200: 165, 243, 252;
  --c-primary-300: 103, 232, 249;
  --c-primary-400: 34, 211, 238;
  --c-primary-500: 6, 182, 212;
  --c-primary-600: 8, 145, 178;
  --c-primary-700: 14, 116, 144;
  --c-primary-800: 21, 94, 117;
  --c-primary-900: 22, 78, 99;

  // SECONDARY COLOR
  --c-secondary-50: 240, 253, 250;
  --c-secondary-100: 204, 251, 241;
  --c-secondary-200: 153, 246, 228;
  --c-secondary-300: 153, 246, 228;
  --c-secondary-400: 45, 212, 191;
  --c-secondary-500: 20, 184, 166;
  --c-secondary-600: 13, 148, 136;
  --c-secondary-700: 15, 118, 110;
  --c-secondary-800: 17, 94, 89;
  --c-secondary-900: 19, 78, 74;
  // NEUTRAL COLOR
  --c-neutral-50: 248, 250, 252;
  --c-neutral-100: 241, 245, 249;
  --c-neutral-200: 226, 232, 240;
  --c-neutral-300: 203, 213, 225;
  --c-neutral-400: 148, 163, 184;
  --c-neutral-500: 100, 116, 139;
  --c-neutral-600: 71, 85, 105;
  --c-neutral-700: 51, 65, 85;
  --c-neutral-800: 30, 41, 59;
  --c-neutral-900: 15, 23, 42;
}

// THEME PALETTE 3 ==========================================
.theme-purple-blueGrey {
  --c-primary-50: 250, 245, 255;
  --c-primary-100: 243, 232, 255;
  --c-primary-200: 233, 213, 255;
  --c-primary-300: 216, 180, 254;
  --c-primary-400: 192, 132, 252;
  --c-primary-500: 168, 85, 247;
  --c-primary-600: 147, 51, 234;
  --c-primary-700: 126, 34, 206;
  --c-primary-800: 107, 33, 168;
  --c-primary-900: 88, 28, 135;
  // SECONDARY COLOR
  --c-secondary-50: 240, 253, 250;
  --c-secondary-100: 204, 251, 241;
  --c-secondary-200: 153, 246, 228;
  --c-secondary-300: 153, 246, 228;
  --c-secondary-400: 45, 212, 191;
  --c-secondary-500: 20, 184, 166;
  --c-secondary-600: 13, 148, 136;
  --c-secondary-700: 15, 118, 110;
  --c-secondary-800: 17, 94, 89;
  --c-secondary-900: 19, 78, 74;
  // NEUTRAL COLOR
  --c-neutral-50: 248, 250, 252;
  --c-neutral-100: 241, 245, 249;
  --c-neutral-200: 226, 232, 240;
  --c-neutral-300: 203, 213, 225;
  --c-neutral-400: 148, 163, 184;
  --c-neutral-500: 100, 116, 139;
  --c-neutral-600: 71, 85, 105;
  --c-neutral-700: 51, 65, 85;
  --c-neutral-800: 30, 41, 59;
  --c-neutral-900: 15, 23, 42;
}
