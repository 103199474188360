.customMarker {
    position: relative;
    display: inline-block; /* Cambiamos a inline-block para ajustar dinámicamente el tamaño */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    font-weight: bold;
    color: black;
    padding: 0; /* Eliminamos el padding adicional */
    margin: 0; /* Evitamos márgenes innecesarios */
    width: auto; /* Ancho dinámico */
    height: auto; /* Altura dinámica */
    box-sizing: border-box; /* Asegura que el padding y el borde no alteren el tamaño */
}



.priceBox {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: white;
    color: black;
    text-align: center;
    border: 1px solid #ccc;
}

.cardContainer {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.customIcon {
    background: transparent;
    border: none;
}

.cardContainer {
    background-color: white;
    border-radius: 0px;
    padding: 0px;
    position: absolute;
    z-index: 1000;
}

.stayCard {
    width: 300px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: none; /* Eliminamos la sombra adicional */
    padding: 0; /* Eliminamos cualquier padding extra */
    margin: 0;
    position: relative; /* Cambiamos a relative si se usa absolute en el contenedor padre */
    z-index: 10; /* Asegura que la tarjeta se muestre encima del mapa */
}
  
  .priceBox {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: white;
    color: black;
    text-align: center;
    border: 1px solid #ccc;
    display: inline-block; /* Asegura que se ajuste al contenido */
    box-sizing: border-box; /* Evita que el padding expanda la caja */
    width: auto; /* Deja que el ancho se ajuste al contenido */
    min-width: 50px; /* Opcional: ancho mínimo */
}
